export const FontSizeList = [
  '10',
  '11',
  '12',
  '13',
  '14',
  '16',
  '18',
  '20',
  '22',
  '24',
];

export const FontFamilyList = ['Inconsolata', 'Source Code Pro'];
