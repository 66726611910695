import * as React from 'react';
import { alpha, Theme } from '@material-ui/core/styles';
import { Button, ButtonProps, styled } from '@material-ui/core';

export const CollapsibleButton = styled(Button)(
  ({ theme }: { theme: Theme } & ButtonProps) => ({
    color: theme.palette.text.header,
    '&:hover': { color: theme.palette.text.header },
    '&:focus': { color: theme.palette.text.header, textDecoration: 'none' },
    backgroundColor: 'transparent',
    padding: '0 10px',
    height: 38,

    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      '& > * > .material-icons + *, & > * > *:has(> .material-icons) + *': {
        display: 'none',
      },
    },
  }),
) as typeof Button;
