import * as React from 'react';
import {
  Badge,
  Box,
  Button,
  LinearProgress,
  styled,
  Tab,
  Tabs,
  TabsActions,
  ThemeProvider,
  Tooltip,
  withTheme,
} from '@material-ui/core';
import {
  createTheme,
  withStyles,
  alpha,
  Theme,
} from '@material-ui/core/styles';
import * as Colors from '@material-ui/core/colors';
import * as _ from 'lodash';
import { PdfViewer } from './PdfViewer';
import { t } from '../../../i18n';
import * as CL2Types from '../../../CL2Types';
import { ResultError, ResultWarning } from '../../../CL2Types';
import EditorActions from '../../../actions/EditorActions';
import { CompileMode } from '../../../constants/EditorConstants';
import TeXDocViewer from './TeXDocViewer';
import { PaneSplitterContext } from './PaneSplitter';
import { RequireEdit } from './permission/RequireEdit';

const LinearProgressEx = withStyles({
  colorPrimary: { backgroundColor: '#42a5f5' },
  barColorPrimary: { backgroundColor: '#4fc3f7' },
  root: {
    zIndex: 1,
  },
})(LinearProgress);

interface Props {
  compiling: boolean;
  result: CL2Types.Result;
  texDocSearching: boolean;
  documentResult: CL2Types.DocumentResult;
  pdfPageInfo: CL2Types.PdfPageInfo;
  project?: {
    display_warnings: boolean;
  };
  userSetting?: {
    display_warnings: boolean;
  };
  scrollSync: boolean;
  showSynctexPdfIndicator: boolean;
  tabValue: number;
  theme: Theme;
  showPdfViewLoading: boolean;
  invalidateFileCacheHandler: () => void;
  openInvalidateFileCacheDialog: (open: boolean) => void;
  pdfLoading: boolean;
  onPdfLoaded: () => void;
  onPdfLoadStarted: () => void;
}

const TabBarDiv = styled('div')(({ theme }) => ({
  flex: '0 0 36px',
  background: theme.palette.background.toolbar,
  minHeight: 0,
  color: theme.palette.text.toolbar,
  display: 'flex',
}));

const Pre = styled('pre')(({ theme }) => ({
  background: theme.palette.background.primary,
  color: theme.palette.text.primary,
}));

class Viewer extends React.Component<Props, {}> {
  static _theme = (outerTheme) =>
    createTheme(
      {
        overrides: {
          MuiTabs: {
            root: { minHeight: 0 },
            indicator: { background: '#4fc3f7' },
            flexContainer: { height: '36px' },
          },
          MuiTab: {
            root: {
              padding: '6px 0px',
              lineHeight: 1.5,
            },
            textColorInherit: {
              color: alpha(outerTheme.palette.text.toolbar, 0.7),
              opacity: 'unset',
              '&$selected': {
                color: outerTheme.palette.text.toolbar,
                opacity: 'unset',
              },
              '&$disabled': {
                color: alpha(outerTheme.palette.text.toolbar, 0.4),
                opacity: 'unset',
              },
            },
          },
        },
      },
      outerTheme,
    );

  private tabsRef: React.RefObject<HTMLButtonElement>;

  private tabsActionsRef: React.RefObject<TabsActions>;

  private resizeObserver: ResizeObserver;

  constructor(props) {
    super(props);
    this.tabsRef = React.createRef();
    this.tabsActionsRef = React.createRef();
  }

  handleResize = _.debounce(() => {
    this.tabsActionsRef.current.updateIndicator();
    this.tabsActionsRef.current.updateScrollButtons();
  });

  componentDidMount() {
    this.resizeObserver = new ResizeObserver((entries, observer) => {
      this.handleResize();
    });
    this.resizeObserver.observe(this.tabsRef.current);
  }

  componentWillUnmount() {
    this.handleResize.cancel();
    this.resizeObserver.unobserve(this.tabsRef.current);
  }

  _render_error_tab_label(
    errors,
    warnings,
    labelStringStyle: React.CSSProperties,
    displayWarnings,
  ) {
    const size = 24;
    let count = 0;
    let label = <span style={labelStringStyle}>Error</span>;

    (displayWarnings
      ? [
          { ews: errors, color: Colors.red },
          { ews: warnings, color: Colors.orange },
        ]
      : [{ ews: errors, color: Colors.red }]
    ).forEach((info) => {
      if (!_.isEmpty(info.ews)) {
        const BadgeEx = withStyles({
          badge: {
            fontSize: '12px',
            backgroundColor: info.color.A200,
            color: 'white',
            width: `${size}px`,
            height: `${size}px`,
            top: '-5px',
            right: `${-(size + 1) * count - 30}px`,
            transform: 'none',
            borderRadius: '50%',
          },
        })(Badge);
        label = (
          <BadgeEx badgeContent={info.ews.length} overlap="rectangular">
            {label}
          </BadgeEx>
        );
        count += 1;
      }
    });

    return label;
  }

  _render_error_and_warnings(
    errors: ResultError[],
    warnings: ResultWarning[],
    displayWarnings,
  ) {
    const baseStyle_: React.CSSProperties = {
      whiteSpace: 'pre-wrap',
      fontFamily: 'Menlo, Monaco, Consolas, "Courier New"',
    };
    const informationStyle_: React.CSSProperties = {
      border: 'unset',
      backgroundColor: 'unset',
      ...baseStyle_,
    };
    let i = 0;
    const elems = [];

    if (
      [CompileMode.PANDOC, CompileMode.RTEX].includes(this.props.result.mode)
    ) {
      elems.push(
        <Pre key={i} style={informationStyle_}>
          {t('view:editor.error_tab.pandoc_warning')}
        </Pre>,
      );
      i += 1;
    }

    (displayWarnings
      ? [
          {
            severity: 'error',
            ews: errors,
            color: this.props.theme.palette.text.error,
            bgcolor: this.props.theme.palette.background.error,
          },
          {
            severity: 'warning',
            ews: warnings,
            color: this.props.theme.palette.text.warning,
            bgcolor: this.props.theme.palette.background.warning,
          },
        ]
      : [
          {
            severity: 'error',
            ews: errors,
            color: this.props.theme.palette.text.error,
            bgcolor: this.props.theme.palette.background.error,
          },
        ]
    ).forEach((info) => {
      const baseStyle: React.CSSProperties = {
        color: info.color,
        ...baseStyle_,
      };
      const informationStyle: React.CSSProperties = {
        ...informationStyle_,
        ...baseStyle,
      };
      const titleStyle: React.CSSProperties = {
        border: `1px solid ${info.color}`,
        ...baseStyle,
      };
      elems.push(
        <Pre key={i} style={titleStyle}>
          <strong>{t(`view:editor.error_tab.${info.severity}`)}</strong>
        </Pre>,
      );
      i += 1;
      if (_.isEmpty(info.ews)) {
        elems.push(
          <Pre key={i} style={informationStyle}>
            {t(`view:editor.error_tab.no_${info.severity}s`)}
          </Pre>,
        );
        i += 1;
      } else {
        elems.push(
          <Pre key={i} style={informationStyle}>
            {t(`view:editor.error_tab.some_${info.severity}s`)}
          </Pre>,
        );
        i += 1;
        for (const ew of info.ews) {
          const lines = [];

          if (ew.filename) {
            lines.push(ew.filename);
          }

          let log;

          switch (info.severity) {
            case 'error':
              log = (ew as ResultError).error_log;
              break;
            case 'warning':
              log = (ew as ResultWarning).warning_log;
              break;
            default:
            // assert(false);
          }

          let lineno;

          if (ew.line) {
            lineno = ew.line;
          } else {
            const result = /on input line +(\d+)/g.exec(log);

            if (result && result.length > 1) {
              lineno = result[1];
            }
          }

          if (lineno) {
            lines.push(`line ${lineno}`);
          }

          lines.push(log);

          let url = null;

          if (ew.url !== undefined) {
            url = (
              <p>
                <a target="_blank" rel="noopener noreferrer" href={ew.url}>
                  {ew.url}
                </a>
              </p>
            );
          }

          const message = lines.join('\n');
          let messageElem;

          if (ew.message) {
            messageElem = (
              <p>
                {message}
                <span dangerouslySetInnerHTML={{ __html: ew.message }} />
              </p>
            );
          } else {
            messageElem = <p>{message}</p>;
          }

          if (ew.answer !== undefined && url) {
            url = (
              <Tooltip
                key={i}
                title={
                  <span
                    style={{
                      fontSize: '14px',
                      lineHeight: 'normal',
                    }}
                  >
                    {ew.answer.split('\n').map((text) => (
                      <p key={message + text}>{text}</p>
                    ))}
                  </span>
                }
              >
                {url}
              </Tooltip>
            );
            i += 1;
          }

          elems.push(
            <Pre
              key={i}
              style={{
                backgroundColor: info.bgcolor,
                ...titleStyle,
              }}
            >
              {messageElem}
              {url}
            </Pre>,
          );
          i += 1;
        }
      }
    });

    return elems;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.theme !== nextProps.theme ||
      this.props.compiling !== nextProps.compiling ||
      this.props.showPdfViewLoading !== nextProps.showPdfViewLoading ||
      this.props.tabValue !== nextProps.tabValue ||
      this.props.result.timestamp !== nextProps.result.timestamp ||
      this.props.pdfPageInfo !== nextProps.pdfPageInfo ||
      this.props.showSynctexPdfIndicator !==
        nextProps.showSynctexPdfIndicator ||
      this.props.texDocSearching !== nextProps.texDocSearching ||
      this.props.pdfLoading !== nextProps.pdfLoading ||
      (this.props.project &&
        nextProps.project &&
        this.props.project.display_warnings !==
          nextProps.project.display_warnings) ||
      (!this.props.project &&
        nextProps.project &&
        nextProps.project.display_warnings !== null) ||
      (!nextProps.project &&
        this.props.project &&
        this.props.project.display_warnings !== null) ||
      this.props.userSetting?.display_warnings !==
        nextProps.userSetting?.display_warnings
    ) {
      return true;
    }
    return false;
  }

  render() {
    const errors = this.props.result.errors ? this.props.result.errors : [];
    const warnings = this.props.result.warnings
      ? this.props.result.warnings
      : [];

    const tabStyle: React.CSSProperties = {
      flex: '1 1 33.333%',
      minWidth: 0,
      minHeight: 0,
      maxWidth: 'inherit',
    };
    const labelStringStyle: React.CSSProperties = {
      fontSize: '14px',
      lineHeight: 1.142857142857,
      position: 'relative',
      top: '-1px',
      maxWidth: 'inherit',
    };

    const displayWarnings = this.props.userSetting
      ? (this.props.userSetting.display_warnings ??
        this.props.project?.display_warnings)
      : true;

    const tmp = (
      <div
        id="app_viewer_root"
        style={{
          height: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {(this.props.compiling || this.props.pdfLoading) && (
          <LinearProgressEx
            style={{
              position: 'absolute',
              left: '0px',
              top: '0px',
              right: '0px',
              height: '4px',
            }}
          />
        )}
        <TabBarDiv>
          <PaneSplitterContext.Consumer>
            {(value) => value.leftButton}
          </PaneSplitterContext.Consumer>
          <Tabs
            value={this.props.tabValue}
            onChange={(e, v) => {
              EditorActions.changeTab(v);
            }}
            style={{ flex: '1 1 auto' }}
            ref={this.tabsRef}
            action={this.tabsActionsRef}
          >
            <Tab
              label={<span style={labelStringStyle}>PDF view</span>}
              style={tabStyle}
              disableRipple
            />
            <Tab
              label={this._render_error_tab_label(
                errors,
                warnings,
                labelStringStyle,
                displayWarnings,
              )}
              style={tabStyle}
              disableRipple
            />
            <Tab
              label={<span style={labelStringStyle}>Log</span>}
              style={tabStyle}
              disableRipple
            />
            <RequireEdit>
              <Tab
                label={<span style={labelStringStyle}>TeXDoc</span>}
                style={tabStyle}
                disableRipple
              />
            </RequireEdit>
          </Tabs>
          <PaneSplitterContext.Consumer>
            {(value) => value.rightButton}
          </PaneSplitterContext.Consumer>
        </TabBarDiv>
        <PdfViewer
          uri={this.props.result.uri}
          errors={this.props.result.errors}
          pdfPageInfo={this.props.pdfPageInfo}
          showSynctexPdfIndicator={this.props.showSynctexPdfIndicator}
          scrollSync={this.props.scrollSync}
          visible={this.props.tabValue === 0}
          loading={this.props.showPdfViewLoading}
          onPdfLoaded={this.props.onPdfLoaded}
          onPdfLoadStarted={this.props.onPdfLoadStarted}
        />
        {this.props.tabValue === 1 && (
          <div style={{ flex: '1 1', overflowY: 'auto' }}>
            <Box padding="8px">
              <Button
                variant="contained"
                size="small"
                onClick={() => this.props.openInvalidateFileCacheDialog(true)}
                disableElevation
              >
                {t('view:editor.invalidate_file_cache.title')}
              </Button>
            </Box>
            {this._render_error_and_warnings(errors, warnings, displayWarnings)}
          </div>
        )}
        {this.props.tabValue === 2 && this.props.result.log && (
          <Pre style={{ flex: '1 1', whiteSpace: 'pre-wrap', margin: '0px' }}>
            <Box component="span" color="text.secondary">
              {this.props.result.log}
            </Box>
          </Pre>
        )}
        <TeXDocViewer
          pdfFiles={this.props.documentResult.pdfFiles}
          exitCode={this.props.documentResult.exitCode}
          texDocSearching={this.props.texDocSearching}
          visible={this.props.tabValue === 3}
        />
      </div>
    );
    return <ThemeProvider theme={Viewer._theme} children={tmp} />;
  }
}

export default withTheme(Viewer);
