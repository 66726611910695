import * as Y from 'yjs';
import { WebsocketProvider } from '@y-rb/actioncable';
import consumer from '../channels/consumer';

let match;
let provider: WebsocketProvider;

export const yDoc = new Y.Doc();
export function websocketProvider() {
  if (
    !provider &&
    (match = window.location.pathname.match(/^\/projects\/([^\/]+)/))
  ) {
    provider = new WebsocketProvider(
      yDoc,
      consumer,
      'CollaborationChannel',
      { id: match[1] },
      { disableBc: true },
    );
    setInterval(() => {
      if (provider?.synced) {
        provider.channel.perform('ping');
      }
    }, 60000);
  }

  return provider;
}
