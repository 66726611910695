import * as React from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Icon,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import * as Colors from '@material-ui/core/colors';
import { Property } from 'csstype';
import { ThemeProvider } from '@material-ui/styles';
import * as _ from 'lodash';
import { t } from '../../i18n';
import LoadingDialog from '../LoadingDialog';
import ProjectsActions from '../../actions/ProjectsActions';
import * as CL2Types from '../../CL2Types';
import AppDispatcher from '../../dispatcher/AppDispatcher';
import ProjectsConstants from '../../constants/ProjectsConstants';

interface SelectProps {
  id: string;
  style: React.CSSProperties;
  InputLabelProps: { style: React.CSSProperties };
  InputProps: { style: React.CSSProperties };
  label: string;
  value: string;
  onChange: (e) => void;
}

class SelectEx extends React.Component<SelectProps, {}> {
  static _theme = createTheme({
    overrides: {
      MuiInput: {
        underline: {
          '&:hover:before': {
            borderBottom: `1px solid ${Colors.grey['300']} !important`,
          },
          '&:before': {
            bottom: '1px',
            borderBottomColor: Colors.grey['300'],
          },
          '&:after': {
            bottom: '1px',
            borderBottomColor: Colors.cyan['500'],
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: 'rgba(0, 0, 0, 0.3)',
          '&$focused': {
            color: Colors.cyan['500'],
          },
        },
      },
    },
  });

  render() {
    const labelId = `${this.props.id}-label`;
    return (
      <MuiThemeProvider theme={SelectEx._theme}>
        <FormControl style={this.props.style}>
          <InputLabel id={labelId} style={this.props.InputLabelProps.style}>
            {this.props.label}
          </InputLabel>
          <Select
            id={this.props.id}
            labelId={labelId}
            style={this.props.InputProps.style}
            value={this.props.value}
            onChange={this.props.onChange}
          >
            {this.props.children}
          </Select>
        </FormControl>
      </MuiThemeProvider>
    );
  }
}

interface TextFieldProps {
  autoFocus: boolean;
  style: React.CSSProperties;
  InputLabelProps: { style: React.CSSProperties };
  InputProps: { style: React.CSSProperties };
  FormHelperTextProps?: { style: React.CSSProperties };
  label: string;
  value: string;
  onKeyDown: (e) => void;
  error?: boolean;
  helperText?: string;
  onChange: (e) => void;
  type: 'text';
}
class TextFieldEx extends React.Component<TextFieldProps, {}> {
  static _theme = createTheme({
    overrides: {
      MuiInput: {
        underline: {
          '&:hover:before': {
            borderBottom: `1px solid ${Colors.grey['300']} !important`,
          },
          '&:before': {
            bottom: '1px',
            borderBottomColor: Colors.grey['300'],
          },
          '&:after': {
            bottom: '1px',
            borderBottomColor: Colors.cyan['500'],
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: 'rgba(0, 0, 0, 0.3)',
          '&$focused': {
            color: Colors.cyan['500'],
          },
        },
      },
    },
  });

  constructor(props) {
    super(props);
  }

  render() {
    const textField = (
      <TextField {...this.props} autoFocus={this.props.autoFocus} />
    );
    return (
      <MuiThemeProvider theme={TextFieldEx._theme}>
        {textField}
      </MuiThemeProvider>
    );
  }
}

interface Props {
  dialogRef: (dialog: ProjectsDialog) => void;
  open: boolean;
  onClose: (buttonClicked: boolean) => void;
  title: string;
  zip: {
    file?: CL2Types.UploadFile;
  };
}
interface State {
  errorText: string;
  submittable: boolean;
  loading: boolean;
  titleInput: string;
  descriptionInput: string;
  compilerInput: string;
  templateInput: string;
  isDragging: boolean;
}
class ProjectsDialog extends React.Component<Props, State> {
  static _dummyTheme = createTheme({});

  static _buttonTheme = createTheme({
    overrides: {
      MuiButton: {
        root: {
          padding: '0px',
          borderRadius: '2px',
        },
        contained: {
          backgroundColor: 'transparent',
          boxShadow:
            'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.4)',
          },
          '&:active': {
            boxShadow:
              'rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px',
          },
        },
        label: {
          height: '36px',
          color: 'white',
        },
      },
    },
  });

  private fileSelector: React.RefObject<HTMLInputElement> =
    React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);
    props.dialogRef(this);
    this.state = {
      errorText: '',
      submittable: false,
      loading: false,
      titleInput: '',
      descriptionInput: '',
      compilerInput: 'platex',
      templateInput: 'default_template_project',
      isDragging: false,
    };
    this.onSelectFile = this.onSelectFile.bind(this);
    this._openFileDialog = this._openFileDialog.bind(this);
  }

  onSelectFile(e): void {
    const file = e.target.files[0];
    if (file) {
      const validFile = this._validateFile(e.target.files);
      if (validFile) {
        const projectName = file.name.replace(/\.zip$/, '');
        this.setState({ titleInput: projectName }, () => {
          this._validateText(projectName);
        });
      }
      this._selectFile(e.target.files);
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //  return this.state != nextState || this.props.open != nextProps.open;
  // }

  private onClose = () => {
    this.setState({
      errorText: '',
      submittable: false,
      titleInput: '',
      descriptionInput: '',
      templateInput: 'default_template_project',
    });
    this.props.onClose(true);
    ProjectsActions.clearZip();
  };

  // 現状、ここは呼ばれないはず (ProjectsAppの_onCloseNewDialogのコメントを参照)
  // getTitleInput() {
  //  return this.state.titleInput || null;
  // }

  getDescriptionInput() {
    return this.state.descriptionInput || null;
  }

  // 空文字列かどうか
  _isEmpty(str) {
    return str.length === 0;
  }

  // 制御文字を含んでいるか?
  _containsControls(str) {
    return /[\x00-\x1F\x7F]/.test(str);
  }

  // 使用できない文字が含まれているか
  _containsSpecialCharacters(str) {
    return /["#\$%\&'\*,\/:;<=>\?\[\\\]\^`\{\|\}~]/.test(str);
  }

  // ドットではじまるか
  _startsWithDot(str) {
    return /^\./.test(str);
  }

  // 空白で始まるか
  _startsWithWhitespace(str) {
    return /^\s/.test(str);
  }

  // 空白で終わるか
  _endsWithWhitespace(str) {
    return /\s$/.test(str);
  }

  private _validateText = (text) => {
    // 入力されたテキストがvalidationを通るか, 通らなかったらそのエラーの説明をセットする
    // TODO サーバーサイドのvalidationと異なる(Windows予約ファイル名の除外などがフロント側では実装されていない). 将来合わせるべき
    if (this._isEmpty(text)) {
      this.setState({ errorText: '', submittable: false });
    } else if (this._containsControls(text)) {
      this.setState({
        errorText: t('view:editor.invalid_control_filename'),
        submittable: false,
      });
    } else if (this._containsSpecialCharacters(text)) {
      this.setState({
        errorText: t('view:editor.invalid_special_char_filename'),
        submittable: false,
      });
    } else if (this._startsWithDot(text)) {
      this.setState({
        errorText: t('view:editor.invalid_starts_with_dot'),
        submittable: false,
      });
    } else if (this._startsWithWhitespace(text)) {
      this.setState({
        errorText: t('view:editor.invalid_starts_with_space'),
        submittable: false,
      });
    } else if (this._endsWithWhitespace(text)) {
      this.setState({
        errorText: t('view:editor.invalid_ends_with_space'),
        submittable: false,
      });
    } else {
      this.setState({ errorText: '', submittable: true });
    }
  };

  private _handleCreateProject = () => {
    const title = this.state.titleInput;
    if (
      !this.state.submittable ||
      (this.state.templateInput === 'upload_zip' && !this.props.zip.file) ||
      title === ''
    ) {
      return;
    }
    this.setState({ loading: true, submittable: false });

    const compiler = this.state.compilerInput;

    ProjectsActions.createProject(
      title,
      this.state.descriptionInput,
      this.state.templateInput,
      this.props.zip.file,
      compiler,
    ).then(
      () => {
        AppDispatcher.dispatch({
          actionType: ProjectsConstants.CLEAR_PROJECT_QUERIES,
        });
        this.setState({ loading: false });
        this.onClose();
        ProjectsActions.loadProjects();
        ProjectsActions.clearSelected();
      },
      () => {
        this.setState({ loading: false, submittable: true });
      },
    );
  };

  private _selectFile(files): void {
    const file = this._validateFile(files);
    if (file) {
      ProjectsActions.uploadZip(file);
      if (this.state.titleInput === '') {
        const title = file.name.replace(/\.zip/i, '');
        this._validateText(title);
        this.setState({ titleInput: title });
      }
    }
  }

  private _openFileDialog(): void {
    this.fileSelector.current.click();
  }

  _validateFile(files): CL2Types.UploadFile {
    const file = files.item ? files.item(0) : files[0];

    if (
      !['application/zip', 'application/x-zip-compressed'].includes(file.type)
    ) {
      return null;
    }

    return file;
  }

  private _handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  private _handleDrop = (e) => {
    e.preventDefault();
    this.setState({ isDragging: false });
    const file = e.dataTransfer.files[0];
    if (file) {
      const validFile = this._validateFile(e.dataTransfer.files);
      if (validFile) {
        const projectName = file.name.replace(/\.zip$/, '');
        this.setState({ titleInput: projectName }, () => {
          this._validateText(projectName);
        });
      }
      this._selectFile(e.dataTransfer.files);
    }
  };

  render() {
    const dialogMaxWidth = 48 * 12; // desktopKeylineIncrement * 12
    const dialog = this;
    const textFieldStyle = { width: '256px', height: '72px' };
    const styleFontSize16 = { style: { fontSize: '16px' } };
    const floatRight: Property.Float = 'right';
    const floatLeft: Property.Float = 'left';
    const addFileStyle: React.CSSProperties = {
      position: 'relative',
      top: '1px',
    };
    const zipFileButtonStyle: React.CSSProperties = {
      flex: 'none',
      textAlign: 'center',
    };
    const compilers = ['platex', 'uplatex', 'lualatex', 'pdflatex', 'xelatex'];
    let zipSection = null;

    if (this.state.templateInput === 'upload_zip') {
      let zipFileName = null;

      if (this.props.zip.file) {
        zipFileName = (
          <div key="zipFileNameDiv" style={{ display: 'inline-block' }}>
            {this.props.zip.file.name}
          </div>
        );
      }

      zipSection = (
        <div
          style={zipFileButtonStyle}
          onDragOver={this._handleDragOver}
          onDrop={this._handleDrop}
        >
          <div
            style={{
              padding: '40px',
              border: this.state.isDragging
                ? '2px dashed #2196f3'
                : '2px dashed #ccc',
              borderRadius: '8px',
              backgroundColor: this.state.isDragging
                ? 'rgba(33, 150, 243, 0.04)'
                : 'transparent',
              textAlign: 'center',
              color: this.state.isDragging ? '#2196f3' : '#666',
              transition: 'all 0.3s ease',
              cursor: 'pointer',
              position: 'relative',
            }}
            tabIndex={0}
            role="button"
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                this._openFileDialog();
              }
            }}
            onDragOver={(e) => {
              e.preventDefault();
              this.setState({ isDragging: true });
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              this.setState({ isDragging: false });
            }}
            onClick={() => this._openFileDialog()}
          >
            {this.props.zip.file ? (
              <div>
                <Icon style={{ fontSize: '36px', marginBottom: '8px' }}>
                  check_circle
                </Icon>
                <div>{this.props.zip.file.name}</div>
                <div
                  style={{ fontSize: '0.9em', marginTop: '8px', opacity: 0.7 }}
                >
                  {t('view:projects.click_to_change')}
                </div>
              </div>
            ) : (
              <div>
                <Icon style={{ fontSize: '48px', marginBottom: '12px' }}>
                  cloud_upload
                </Icon>
                <div>{t('view:projects.drag_and_drop_zip')}</div>
                <div
                  style={{ fontSize: '0.9em', marginTop: '8px', opacity: 0.7 }}
                >
                  {t('view:projects.or_click_to_select')}
                </div>
              </div>
            )}
          </div>
          <input
            ref={this.fileSelector}
            type="file"
            accept=".zip"
            style={{ display: 'none' }}
            onChange={(e) => this.onSelectFile(e)}
          />
        </div>
      );
    }

    return (
      <LoadingDialog
        open={this.props.open}
        onClose={this.onClose}
        onRequestExec={this._handleCreateProject}
        waiting={this.state.loading}
        execLabel={t('view:projects.create')}
        disabled={
          !this.state.submittable ||
          (this.state.templateInput === 'upload_zip' && !this.props.zip.file)
        }
        PaperProps={{
          style: { width: '75%', maxWidth: `${dialogMaxWidth}px` },
        }}
        title={this.props.title}
      >
        <FormControl>
          <RadioGroup
            id="template"
            name="template"
            value={this.state.templateInput}
            onChange={(e) => this.setState({ templateInput: e.target.value })}
            row
          >
            {['default_template_project', 'blank_project', 'upload_zip'].map(
              (item) => (
                <FormControlLabel
                  key={item}
                  value={item}
                  control={<Radio />}
                  label={
                    <Typography style={styleFontSize16.style}>
                      {t(`view:projects.${item}`)}
                    </Typography>
                  }
                />
              ),
            )}
          </RadioGroup>
        </FormControl>
        {zipSection}
        <div style={{ position: 'relative', top: '16px' }}>
          <TextFieldEx
            autoFocus
            style={_.defaults({ float: floatLeft }, textFieldStyle)}
            InputLabelProps={styleFontSize16}
            InputProps={styleFontSize16}
            FormHelperTextProps={{ style: { fontSize: '12px' } }}
            label={t('view:projects.project_title')}
            value={this.state.titleInput}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter' && ev.keyCode === 13) {
                ev.stopPropagation();
                dialog._handleCreateProject();
              }
            }}
            error={this.state.errorText !== ''}
            helperText={
              this.state.errorText || ' ' /* This ' ' is a place holder */
            }
            onChange={(e) => {
              const s = e.target.value;
              this._validateText(s);
              this.setState({ titleInput: s });
            }}
            type="text"
          />
          <TextFieldEx
            autoFocus={false}
            style={_.defaults({ float: floatRight }, textFieldStyle)}
            InputLabelProps={styleFontSize16}
            InputProps={styleFontSize16}
            label={t('view:projects.description')}
            value={this.state.descriptionInput}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter' && ev.keyCode === 13) {
                ev.stopPropagation();
                dialog._handleCreateProject();
              }
            }}
            onChange={(e) =>
              this.setState({ descriptionInput: e.target.value })
            }
            type="text"
          />
          <SelectEx
            id="compiler"
            style={_.defaults({ float: floatLeft }, textFieldStyle)}
            InputLabelProps={styleFontSize16}
            InputProps={styleFontSize16}
            label={t('view:editor.compiler')}
            value={this.state.compilerInput}
            onChange={(e) => this.setState({ compilerInput: e.target.value })}
          >
            {compilers.map((item) => (
              <MenuItem value={item} key={item}>
                <span {...styleFontSize16}>{item}</span>
              </MenuItem>
            ))}
          </SelectEx>
        </div>
      </LoadingDialog>
    );
  }
}

export default ProjectsDialog;
