import * as request from 'superagent';
import { SuperAgentRequest } from 'superagent';
import { captureException } from '@sentry/react';
import { APIRoot, CSRFToken } from '../constants/CommonConstants';
import HandleKinds from '../constants/HandleKinds';
import { editorStore as store } from '../store/index';
import EditorActions from '../actions/EditorActions';
import { getCurrentFile } from '../reducers/editor';
import * as CL2Types from '../CL2Types';
import { relativePathFromUploadFile } from './CommonUtils';
import {
  PermissionControlled,
  RequireEdit,
  RequireRead,
  SkipPermissionCheck,
} from './permission';

const ProjectRoot = `${APIRoot + window.location.pathname.replace(/\/(edit|viewer)/, '')}/`;

export const projectId = window.location.pathname.match(
  /^\/projects\/([^\/]+)/,
)?.[1];

export const popoutViewerUrl = window.location.href.replace(
  /(\/projects\/[^\/]+).*/,
  '$1/viewer',
);

const createPromise = (
  request: SuperAgentRequest,
  newRequest = () => request,
) =>
  new Promise<any>((resolve, reject) => {
    request.end((err, res) => {
      if (res && res.status === 409) {
        // conflict
        const json = JSON.parse(res.text);
        EditorActions.conflictFile(json);
        reject(res);
      } else if (!res) {
        // network error
        EditorActions.onOffline();
        reject(err);
      } else if (res.status !== 200) {
        let json;
        try {
          json = JSON.parse(res.text);
        } catch (e) {
          if (res.error) {
            res.error.cause = err;
          }
          captureException(res.error || err);
          reject(res);
        }
        if (json === null || json === undefined) {
          return reject(res);
        }
        if (json.handle_kind === HandleKinds.retry) {
          createPromise(newRequest(), newRequest)
            .then((res) => resolve(res))
            .catch((res) => {
              reject(res);
            });
        } else {
          if (json.handle_kind !== HandleKinds.nothing) {
            EditorActions.apiError(json);
          }
          reject(res);
        }
      } else {
        resolve(res);
      }
    });
  });

@PermissionControlled
class EditorWebAPIUtils {
  @SkipPermissionCheck
  static loadProjectInfo() {
    return createPromise(request.get(ProjectRoot));
  }

  @RequireEdit
  static exportProject(storage_type) {
    return createPromise(
      request
        .post(`${ProjectRoot}export`)
        .set('X-CSRF-Token', CSRFToken())
        .send({ storage_type }),
    );
  }

  @RequireEdit
  static unsyncProject() {
    return createPromise(
      request.post(`${ProjectRoot}unsync`).set('X-CSRF-Token', CSRFToken()),
    );
  }

  @RequireEdit
  static startPollingExportStatus(interval: number) {
    return new Promise((resolve, reject) => {
      const id = setInterval(() => {
        request
          .get(`${ProjectRoot}export_status`)
          .set('X-CSRF-Token', CSRFToken())
          .end((err, res) => {
            const json = JSON.parse(res.text);
            if (res.status === 200) {
              if (json.export_status === 'export_completed') {
                clearInterval(id);
                resolve(res);
              } else if (json.export_status === 'export_error') {
                clearInterval(id);
                reject(res);
              }
            } else {
              clearInterval(id);
              reject(res);
            }
          });
      }, interval);
    });
  }

  @RequireEdit
  static getExportStatus() {
    return new Promise((resolve, reject) => {
      request
        .get(`${ProjectRoot}export_status`)
        .set('X-CSRF-Token', CSRFToken())
        .end((err, res) => {
          const json = JSON.parse(res.text);
          if (res.status === 200) {
            resolve(json);
          } else {
            reject(json);
          }
        });
    });
  }

  @SkipPermissionCheck
  static loadFiles() {
    return createPromise(request.get(`${ProjectRoot}files`));
  }

  @RequireEdit
  static createFile(name: string, belongs: number, is_folder: boolean) {
    return createPromise(
      request
        .post(`${ProjectRoot}files`)
        .set('X-CSRF-Token', CSRFToken())
        .send({
          name,
          is_folder,
          belonging_to: belongs,
          is_new: true,
        }),
    );
  }

  @RequireEdit
  static loadFileVersions(id: number) {
    return createPromise(
      request
        .get(`${ProjectRoot}files/${id}/versions`)
        .set('X-CSRF-Token', CSRFToken()),
    );
  }

  @RequireEdit
  static restoreFile(file_id: number, version_id: string) {
    return new Promise((resolve, reject) => {
      request
        .post(`${ProjectRoot}files/${file_id}/restore/${version_id}`)
        .set('X-CSRF-Token', CSRFToken())
        .end((err, res) => {
          if (res.status === 200) {
            resolve(res);
          } else {
            reject(res);
          }
        });
    });
  }

  @RequireRead
  static openFile(id: number) {
    return createPromise(request.get(`${ProjectRoot}files/${id}`), () => {
      if (
        store.getState().editor.files.filter((f) => f.id === id).length >= 1
      ) {
        return request.get(`${ProjectRoot}files/${id}`);
      }
    });
  }

  @RequireEdit
  static deleteFile(id: number) {
    return createPromise(
      request.del(`${ProjectRoot}files/${id}`).set('X-CSRF-Token', CSRFToken()),
      () => {
        if (
          store.getState().editor.files.filter((f) => f.id === id).length >= 1
        ) {
          return request
            .del(`${ProjectRoot}files/${id}`)
            .set('X-CSRF-Token', CSRFToken());
        }
      },
    );
  }

  @RequireEdit
  static updateFile(id: number, params: any) {
    return createPromise(
      request
        .put(`${ProjectRoot}files/${id}`)
        .set('X-CSRF-Token', CSRFToken())
        .send({ material_file: params }),
      () => {
        if (id === store.getState().editor.currentFileId) {
          params.revision = getCurrentFile(store.getState().editor).revision;
        }
        if (
          store.getState().editor.files.filter((f) => f.id === id).length >= 1
        ) {
          return request
            .put(`${ProjectRoot}files/${id}`)
            .set('X-CSRF-Token', CSRFToken())
            .send({ material_file: params });
        }
      },
    );
  }

  @RequireEdit
  static updateProject(params: any) {
    return createPromise(
      request
        .put(ProjectRoot)
        .set('X-CSRF-Token', CSRFToken())
        .send({ project: params }),
    );
  }

  @SkipPermissionCheck
  static loadUser() {
    return createPromise(request.get(`${APIRoot}/user`));
  }

  @RequireRead
  static reconnect() {
    return request.get(`${ProjectRoot}reconnect`);
  }

  @RequireEdit
  static updateUser(params: any) {
    return createPromise(
      request
        .put(`${APIRoot}/user`)
        .set('X-CSRF-Token', CSRFToken())
        .send({ user: params }),
    );
  }

  @RequireEdit
  static compileProject() {
    return createPromise(
      request.post(`${ProjectRoot}compile`).set('X-CSRF-Token', CSRFToken()),
    );
  }

  @RequireRead
  static fetchCompileResult() {
    return createPromise(request.get(`${ProjectRoot}compile_result`));
  }

  @RequireEdit
  static killCompileProcess() {
    return createPromise(
      request
        .post(`${ProjectRoot}kill_compile`)
        .set('X-CSRF-Token', CSRFToken()),
    );
  }

  // TODO transfer all action dispatch to EditorActions
  @RequireEdit
  static async uploadFiles(form: any, fileList: Array<CL2Types.UploadFile>) {
    for (const file of fileList) {
      try {
        await EditorWebAPIUtils.uploadFile(file);
      } catch (e) {}
    }
  }

  @RequireEdit
  static uploadFile(file: CL2Types.UploadFile) {
    const relativePath = relativePathFromUploadFile(file);
    return new Promise<void>((resolve, reject) => {
      request
        .post(`${ProjectRoot}files/upload`)
        .field('relative_path', relativePath)
        .attach('file', file)
        .on('progress', (evt) => {
          EditorActions.progressUpload({
            loaded: evt.loaded,
            total: evt.total,
            files: [file],
          });
        })
        .set('X-CSRF-Token', CSRFToken())
        .end((err, res) => {
          EditorActions.finishUpload();

          if (err && !res) {
            EditorActions.failedUpload({
              files: [file],
            });
            return reject();
          }

          const json = JSON.parse(res.text);

          if (res.status < 200 || res.status >= 300) {
            EditorActions.apiError(json);
            EditorActions.failedUpload({
              files: [file],
            });
            return reject();
          }

          EditorActions.doneUpload({
            result: json,
          });
          return resolve();
        });
    });
  }

  @RequireEdit
  static setCompileTarget(id: number) {
    return createPromise(
      request
        .put(ProjectRoot)
        .set('X-CSRF-Token', CSRFToken())
        .send({
          project: { compile_target_file_id: id },
        }),
    );
  }

  @RequireEdit
  static resolveAllFiles(source) {
    return createPromise(
      request
        .post(`${ProjectRoot}resolve`)
        .set('X-CSRF-Token', CSRFToken())
        .send({
          file_source: source,
        }),
    );
  }

  @SkipPermissionCheck
  static retryRequest(req, doneFunc, failFunc) {
    if (!req || !req() || !doneFunc || !failFunc) {
      return;
    }
    return createPromise(req(), req).then(doneFunc).catch(failFunc);
  }

  @RequireRead
  static synctexFromEditor(line, column, input) {
    return createPromise(
      request
        .post(`${ProjectRoot}synctex_from_editor`)
        .set('X-CSRF-Token', CSRFToken())
        .send({ line, column, input }),
    );
  }

  @RequireRead
  static loadSynctexObject(url) {
    return createPromise(request.get(url).responseType('arraybuffer'));
  }

  @RequireEdit
  static searchTeXDocument(query: string): Promise<any> {
    return createPromise(
      request
        .post(`${ProjectRoot}search_tex_document`)
        .set('X-CSRF-Token', CSRFToken())
        .send({ query }),
    );
  }

  @RequireEdit
  static autocomplete(compileTargetDependencies) {
    return request
      .get(`${ProjectRoot}autocomplete`)
      .query({ 'deps[]': compileTargetDependencies });
  }

  @RequireEdit
  static createProjectShare() {
    return createPromise(
      request
        .post(`${ProjectRoot}project_shares`)
        .set('X-CSRF-Token', CSRFToken()),
    );
  }

  @RequireEdit
  static updateProjectShare(token, data) {
    return createPromise(
      request
        .put(`${ProjectRoot}project_shares/${token}`)
        .set('X-CSRF-Token', CSRFToken())
        .send(data),
    );
  }

  @RequireEdit
  static invalidateFileCache() {
    return createPromise(
      request
        .post(`${ProjectRoot}invalidate_file_cache`)
        .set('X-CSRF-Token', CSRFToken()),
    );
  }
}

export default EditorWebAPIUtils;
