import * as _ from 'lodash';
import EditorConstants, { CompileMode } from '../constants/EditorConstants';
import { PDFFile } from '../CL2Types';
import EditorActions from '../actions/EditorActions';

const initialState = {
  currentFileId: null,
  project: null,
  user: null,
  files: [],
  target: undefined,
  file_dialog: { appear: false, is_folder: false },
  loading: false,
  waiting: false,
  compiling: false,
  compile_waiting: false,
  fileLoading: false,
  syncing: false,
  result: {
    uri: null,
    jpgs: null,
    exitCode: 0,
    errors: [],
    warnings: [],
    log: null,
    timestamp: 0,
    mode: CompileMode.LATEX,
    synctex_uri: null,
    texcount: null,
  },
  texDocSearching: false,
  documentResult: {
    uri: null,
    pdfFileName: null,
    exitCode: null,
  },
  upload: {
    uploading: false,
    uploaded: 0,
    opened: false,
    progress: [],
    done: [],
    fail: [],
  },
  openSnackbar: false,
  snackbarMessage: '',
  refreshDialog: { open: false, message: '' },
  previewFile: null,
  error: {
    open: false,
    handle_kind: null,
    message: '',
  },
  conflictFileDialog: false,
  conflictFiles: [],
  restoreDialog: {
    versions: [],
    current_version: {},
    loading: true,
    restoring: false,
  },
  pdfPageInfo: {
    originator: 'init',
    page: 1,
    x: null,
    y: null,
  },
  editorPositionInfo: {
    originator: 'init',
    line: null,
    column: null,
  },
  network: 'online',
  editor: null,
  synctexObject: null,
  showSynctexPdfIndicator: false,
  showSynctexEditorIndicator: false,
  tabValue: 0,
  bibtexItems: [],
  countDialog: { open: false },
  shareDialog: { open: false },
  showPdfViewLoading: false,
  selectTargetDialog: { open: false, files: [] },
  invalidateFileCacheDialog: { open: false },
  successInvalidateFileCacheDialog: { open: false },
  pdfLoading: false,
  moveDialog: { open: false, fileDialogOpen: false },
};

const _getFileIndex = (state, fileId) => {
  if (!state.files) {
    // eslint-disable-next-line no-console
    console.error('state.files is null');
    return -1;
  }
  if (fileId === null) {
    // eslint-disable-next-line no-console
    console.error('fileId is null');
    return -1;
  }
  return _.findIndex(
    state.files,
    (file: { id: null | number }) => file.id === fileId,
  );
};

const getFileById = (state, id) => {
  if (!state.files) {
    // files has not been determined
    return null;
  }
  return state.files[_getFileIndex(state, id)];
};

const getFileByFullPath = (state, fullPath) => {
  if (!state.files) {
    // files has not been determined
    return null;
  }
  return _.find(
    state.files,
    (file: { full_path: string | null }) => file.full_path === fullPath,
  );
};

// alternative of getter/setter of state
const getCurrentFile = (state) => {
  if (!state.currentFileId || !state.files) {
    // file has not been determined
    return null;
  }
  return getFileById(state, state.currentFileId);
};

const setCurrentFile = (state, newFile) => {
  const index = _getFileIndex(state, newFile.id);
  if (index < 0) {
    return state;
  }
  const newState = _.assign({}, state, { currentFileId: newFile.id });
  newState.files = _.assign([], newState.files); // copy array (_.assign is shallow copy)
  newState.files[index] = _.assign({}, newState.files[index], newFile);
  return newState;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const editor = (state = initialState, action) => {
  switch (action.type) {
    case EditorConstants.LOAD_PROJECT_INFO:
    case EditorConstants.REQUEST_UPDATE_USER:
      return _.assign({}, state, { loading: true });
    case EditorConstants.RECEIVE_PROJECT_INFO:
      return _.assign({}, state, {
        project: action.json.project,
        loading: false,
      });
    case EditorConstants.RECEIVE_USER_INFO:
    case EditorConstants.SUCCESS_UPDATE_USER:
      return _.assign({}, state, { user: action.json.user, loading: false });
    case EditorConstants.EXPORT_PROJECT:
      return _.assign({}, state, {
        fileLoading: true,
        loading: true,
        syncing: true,
      });
    case EditorConstants.RECEIVE_EXPORT_PROJECT: {
      const newState = _.assign({}, state, {
        fileLoading: false,
        loading: false,
        syncing: false,
      });
      if (action.json) {
        newState.project.sync_target = action.json.target;
      }
      return newState;
    }
    case EditorConstants.SHOW_SNACKBAR: {
      const newState = _.assign({}, state, { openSnackbar: true });
      if (action.json) {
        newState.snackbarMessage = action.json.message;
      } else {
        newState.snackbarMessage = '';
      }
      return newState;
    }
    case EditorConstants.LOAD_FILES:
      return _.assign({}, state, { loading: true });
    case EditorConstants.FAILED_LOAD:
    case EditorConstants.FAILURE_UPDATE_USER:
      return _.assign({}, state, {
        loading: false,
        waiting: false,
      });
    case EditorConstants.RECEIVE_LOAD_FILES: {
      const newState = _.assign({}, state, {
        files: action.json.material_files,
        loading: false,
      });
      /* If current file is not found (ex. current file has been deleted),
       * set current file null
       */
      if (
        newState.currentFileId &&
        !_.find(newState.files, (file) => file.id === newState.currentFileId)
      ) {
        newState.currentFileId = null;
      }
      return newState;
    }
    case EditorConstants.LOAD_FILE_VERSIONS: {
      const newState = _.assign({}, state, {
        restoreDialog: {
          versions: [],
          current_version: {},
          loading: true,
          restoring: false,
        },
      });
      return newState;
    }
    case EditorConstants.RECEIVE_LOAD_FILE_VERSIONS: {
      const newState = _.assign({}, state, {
        restoreDialog: {
          versions: action.json.versions,
          current_version: action.json.current,
          loading: false,
          restoring: false,
        },
      });
      return newState;
    }
    case EditorConstants.RESTORE_FILE: {
      const newState = _.merge({}, state, {
        restoreDialog: {
          restoring: true,
        },
      });
      return newState;
    }
    case EditorConstants.RECEIVE_RESTORE_FILE: {
      const newState = _.merge({}, state, {
        waiting: false,
        restoreDialog: {
          restoring: false,
        },
      });
      _.forEach(state.files, (elem, i) => {
        if (elem.id === action.json.id) {
          newState.files[i].revision = action.json.revision;
          newState.files[i].content = action.json.content;
        }
      });
      return newState;
    }
    case EditorConstants.OPEN_FILE_DIALOG:
      return _.assign({}, state, {
        file_dialog: {
          appear: true,
          is_folder: action.params.is_folder,
        },
        target: action.params.folder,
      });
    case EditorConstants.CLOSE_FILE_DIALOG:
      return _.merge({}, state, {
        file_dialog: {
          appear: false,
        },
        target: undefined,
      });
    case EditorConstants.SEND_OPEN_FILE: {
      let currentFile = null;
      if (state.files) {
        const file = state.files.filter((file) => file.id === action.fileId)[0];
        if (file && file.content) {
          // use cached content
          currentFile = file;
        }
      }
      const fileLoading = !(
        currentFile && currentFile.hasOwnProperty('content')
      );
      const newState = _.assign({}, state, {
        fileLoading,
      });
      if (!fileLoading) {
        newState.currentFileId = action.fileId;
      }
      return newState;
    }
    case EditorConstants.OPEN_FILE: {
      const currentFile = action.json.material_file;
      const newState = setCurrentFile(state, currentFile);
      _.assign(newState, {
        fileLoading: false,
      });
      return newState;
    }
    case EditorConstants.FETCH_FILE: {
      const newFile = action.json.material_file;
      if (newFile.content === null) {
        // Failed to load content; not update state
        return state;
      }
      const index = _getFileIndex(state, newFile.id);
      if (index < 0) {
        return state;
      }
      const newState = { ...state, files: [...state.files] };
      newState.files[index] = _.assign({}, newState.files[index], newFile);
      return newState;
    }
    case EditorConstants.CLOSE_FILE: {
      return _.assign({}, state, {
        fileLoading: false,
        currentFileId: null,
      });
    }
    case EditorConstants.OPEN_BINARY_FILE:
      return _.assign({}, state, {
        fileLoading: false,
        previewFile: getFileById(state, action.id),
      });
    case EditorConstants.CLOSE_BINARY_FILE:
      return _.assign({}, state, { previewFile: null });
    case EditorConstants.OPEN_FOLDER: {
      const folderIndex = state.files.findIndex((f) => f.id === action.id);
      return folderIndex < 0
        ? state
        : {
            ...state,
            files: [
              ...state.files.slice(0, folderIndex),
              {
                ...state.files[folderIndex],
                is_open: action.isOpen,
              },
              ...state.files.slice(folderIndex + 1),
            ],
          };
    }
    case EditorConstants.REQUEST_CREATE:
    case EditorConstants.REQUEST_SAVE:
    case EditorConstants.REQUEST_DELETE: {
      let newState = _.assign({}, state, { waiting: true });
      if (action.params && action.params.hasOwnProperty('content')) {
        newState = setCurrentFile(newState, {
          id: newState.currentFileId,
          content: action.params.content,
        });
      }
      return newState;
    }
    case EditorConstants.DONE_SAVE: {
      let newState = _.assign({}, state, { waiting: false });
      if (state.currentFileId && state.currentFileId === action.id) {
        newState = setCurrentFile(newState, {
          id: newState.currentFileId,
          revision: action.json.revision,
        });
      }
      return newState;
    }
    case EditorConstants.FAILED_CREATE:
    case EditorConstants.FAILED_SAVE:
    case EditorConstants.FAILED_DELETE:
      return _.assign({}, state, { waiting: false });
    case EditorConstants.COMPILE:
      return _.assign({}, state, { compiling: true });
    case EditorConstants.SEARCH_TEX_DOCUMENT:
      return _.assign({}, state, {
        documentResult: {
          pdfFiles: [],
          exitCode: null,
        },
        texDocSearching: true,
      });
    case EditorConstants.MULTIPLE_COMPILE:
    case EditorConstants.COMPILE_WAITING:
      return _.assign({}, state, {
        compile_waiting: true,
      });
    case EditorConstants.DONE_COMPILE: {
      const newState = _.assign({}, state, {
        result: {
          exitCode: action.json.exit_code,
          uri: action.json.uri,
          synctex_uri: action.json.synctex_uri,
          texcount: action.json.texcount,
          jpgs: action.json.jpgs,
          errors: action.json.errors || [],
          warnings: action.json.warnings || [],
          log: action.json.log,
          timestamp: Date.now(),
          mode: action.json.mode,
        },
        compile_waiting: false,
        compiling: false,
        showPdfViewLoading: false,
      });
      if (action.json.uri || action.json.exit_code == null) {
        // exit code is null or undefined
        newState.tabValue = 0;
      } else {
        newState.tabValue = 1;
      }
      if (state.compile_waiting) {
        setTimeout(() => {
          EditorActions.compile();
        }, 0);
      }
      return newState;
    }
    case EditorConstants.FAILED_COMPILE: {
      const newState = _.assign({}, state, {
        compile_waiting: false,
        compiling: false,
        showPdfViewLoading: false,
      });
      if (state.compile_waiting) {
        setTimeout(() => {
          EditorActions.compile();
        }, 0);
      }
      return newState;
    }
    case EditorConstants.DONE_SEARCH_TEX_DOCUMENT:
      return _.assign({}, state, {
        documentResult: {
          pdfFiles: action.json.pdf_files.map(
            (pdfFile): PDFFile => ({
              uri: pdfFile.uri,
              pdfFileName: pdfFile.pdf_file_name,
            }),
          ),
          exitCode: action.json.exit_code,
        },
        texDocSearching: false,
      });
    case EditorConstants.ERR_SEARCH_TEX_DOCUMENT:
      return _.assign({}, state, {
        texDocSearching: false,
      });
    case EditorConstants.DONE_CREATE:
    case EditorConstants.DONE_DELETE:
      return _.assign({}, state, { waiting: false });
    case EditorConstants.START_UPLOAD_SESSION:
      return _.assign({}, state, {
        upload: {
          uploading: false,
          uploaded: 0,
          opened: true,
          progress: [],
          done: [],
          fail: [],
        },
      });
    case EditorConstants.START_UPLOAD:
      return _.merge({}, state, { upload: { uploading: true } });
    case EditorConstants.PROGRESS_UPLOAD: {
      return {
        ...state,
        upload: {
          ...state.upload,
          progress: [action.data, ...state.upload.progress],
        },
      };
    }
    case EditorConstants.FAIL_UPLOAD: {
      return {
        ...state,
        upload: { ...state.upload, fail: [action.data, ...state.upload.fail] },
      };
    }
    case EditorConstants.DONE_UPLOAD: {
      const newState = {
        ...state,
        files: [...state.files],
        upload: { ...state.upload, done: [action.data, ...state.upload.done] },
      };
      if (
        state.files.filter((f) => f.id === action.data.result.file.id).length <
        1
      )
        newState.files.push(action.data.result.file);
      return newState;
    }
    case EditorConstants.FINISH_UPLOAD:
      return _.merge({}, state, {
        upload: { uploaded: state.upload.uploaded + 1 },
      });
    case EditorConstants.FINISH_UPLOAD_SESSION:
      return _.assign({}, state, {
        upload: {
          uploading: false,
          uploaded: 0,
          opened: false,
          progress: [],
          done: [],
          fail: [],
        },
      });
    case EditorConstants.CLOSE_SNACKBAR:
      return _.assign({}, state, { openSnackbar: false });
    case EditorConstants.OPEN_REFRESH_DIALOG:
      return _.assign({}, state, {
        refreshDialog: { open: true, message: action.json.message },
      });
    case EditorConstants.CLOSE_REFRESH_DIALOG:
      return _.assign({}, state, {
        refreshDialog: { open: false, message: '' },
      });
    case EditorConstants.API_ERROR:
      return _.assign({}, state, {
        error: {
          open: true,
          handle_kind: action.json.handle_kind,
          message: action.json.message,
        },
      });
    case EditorConstants.CLOSE_ERROR_DIALOG:
      return _.assign({}, state, {
        error: { ...state.error, open: false },
      });
    case EditorConstants.FILE_CONFLICT:
      return _.assign({}, state, {
        conflictFileDialog: true,
        conflictFiles: action.json.material_files,
      });
    case EditorConstants.RESET_RETRY:
      return _.assign({}, state, {
        waiting: false,
      });
    case EditorConstants.CLOSE_CONFLICT_FILE_DIALOG:
      return _.assign({}, state, {
        conflictFileDialog: false,
      });
    case EditorConstants.RESOLVE_CONFLICT:
      return _.assign({}, state, { waiting: true });
    case EditorConstants.RESOLVE_FINISH:
      const newState = _.assign({}, state, {
        fileLoading: false,
        loading: false,
        waiting: false,
        syncing: false,
        compiling: false,
      });

      return newState;
    case EditorConstants.PDFVIEW_SETPAGE:
      if (
        state.pdfPageInfo.page !== action.json.page ||
        state.pdfPageInfo.x !== action.json.x ||
        state.pdfPageInfo.y !== action.json.y ||
        state.pdfPageInfo.originator !== action.json.originator
      ) {
        const pdfPageInfo = {
          originator: action.json.originator,
          page: action.json.page,
          x: action.json.x,
          y: action.json.y,
        };
        return _.assign({}, state, { pdfPageInfo });
      }
      return state;
    case EditorConstants.NETWORK:
      const network = action.status || 'online';
      return _.assign({}, state, { network, waiting: false });
    case EditorConstants.SHOW_SYNCTEX_PDF_INDICATOR:
      return _.assign({}, state, { showSynctexPdfIndicator: true });
    case EditorConstants.HIDE_SYNCTEX_PDF_INDICATOR:
      return _.assign({}, state, { showSynctexPdfIndicator: false });
    case EditorConstants.SET_EDITOR_POSITION:
      if (
        state.editorPositionInfo.line !== action.json.line ||
        state.editorPositionInfo.column !== action.json.column ||
        state.pdfPageInfo.originator !== action.json.originator
      ) {
        const editorPositionInfo = {
          originator: action.json.originator,
          input: action.json.input,
          line: action.json.line,
          column: action.json.column,
          textBeforeSelection: action.json.textBeforeSelection,
          textAfterSelection: action.json.textAfterSelection,
        };
        return _.assign({}, state, { editorPositionInfo });
      }
      return state;
    case EditorConstants.SHOW_SYNCTEX_EDITOR_INDICATOR:
      return _.assign({}, state, { showSynctexEditorIndicator: true });
    case EditorConstants.HIDE_SYNCTEX_EDITOR_INDICATOR:
      return _.assign({}, state, { showSynctexEditorIndicator: false });
    case EditorConstants.CHANGE_TAB: {
      return _.assign({}, state, { tabValue: action.tabValue });
    }
    case EditorConstants.SET_BIBTEX_ITEMS: {
      return _.assign({}, state, { bibtexItems: action.bibtexItems });
    }
    case EditorConstants.CHANGE_COUNT_DIALOG: {
      return {
        ...state,
        countDialog: { ...state.countDialog, open: action.open },
      };
    }
    case EditorConstants.CHANGE_SHARE_DIALOG: {
      return {
        ...state,
        shareDialog: { ...state.shareDialog, open: action.open },
      };
    }
    case EditorConstants.SUCCESS_SHARE: {
      const index = state.project.shares.findIndex(
        (share) => share.token === action.json.token,
      );
      const shares =
        index < 0
          ? [...state.project.shares, action.json]
          : [
              ...state.project.shares.slice(0, index),
              action.json,
              ...state.project.shares.slice(index + 1),
            ];
      return {
        ...state,
        project: {
          ...state.project,
          shares: shares.filter((share) => share.authority !== 0),
        },
      };
    }
    case EditorConstants.SHOW_PDF_VIEW_LOADING: {
      return { ...state, showPdfViewLoading: true };
    }
    case EditorConstants.OPEN_SELECT_TARGET_DIALOG: {
      return {
        ...state,
        selectTargetDialog: { open: true, files: action.files },
      };
    }
    case EditorConstants.CLOSE_SELECT_TARGET_DIALOG: {
      return { ...state, selectTargetDialog: { open: false, files: [] } };
    }
    case EditorConstants.OPEN_INVALIDATE_FILE_CACHE_DIALOG: {
      return { ...state, invalidateFileCacheDialog: { open: action.open } };
    }
    case EditorConstants.REQUEST_INVALIDATE_FILE_CACHE: {
      return {
        ...state,
        invalidateFileCacheDialog: {
          ...state.invalidateFileCacheDialog,
          disableButton: true,
        },
      };
    }
    case EditorConstants.SUCCESS_INVALIDATE_FILE_CACHE: {
      return { ...state, successInvalidateFileCacheDialog: { open: true } };
    }
    case EditorConstants.CLOSE_SUCCESS_INVALIDATE_FILE_CACHE_DIALOG: {
      return {
        ...state,
        invalidateFileCacheDialog: { open: false },
        successInvalidateFileCacheDialog: { open: false },
      };
    }
    case EditorConstants.PDF_LOADED: {
      return {
        ...state,
        pdfLoading: false,
      };
    }
    case EditorConstants.PDF_LOAD_STARTED: {
      return {
        ...state,
        pdfLoading: true,
      };
    }
    case EditorConstants.CHANGE_MOVE_DIALOG: {
      return {
        ...state,
        moveDialog: {
          ...state.moveDialog,
          ...action.payload,
        },
      };
    }
    case EditorConstants.OVERWRITE: {
      return action.payload.editor;
    }
    default:
      return state;
  }
};

export default editor;
export { getCurrentFile, getFileByFullPath, getFileById };
export type State = typeof initialState;
