import { Blob } from 'buffer';
import { Dictionary } from 'lodash';
import HandleKinds from './constants/HandleKinds';
import { CompileMode } from './constants/EditorConstants';

// editor

export type NetworkStatus = 'online' | 'offline';

export interface EditorUser {
  dropbox_linked: boolean;
  state: number; // enum?
  features: { [key: string]: true };

  user_setting: UserSetting;
}

export interface UserSetting {
  scroll_sync: boolean;
  key_bindings: string;
  display_warnings: boolean;
  editor_theme: string;
  font_size: string;
  font_family: string;
  line_wrapping: boolean;
}

export interface CurrentFile extends ProjectFile {
  content: string;
}

export type ConflictFile = Array<any>; // XXX should use narrower type

export interface EditorError {
  open: boolean;
  handle_kind: HandleKinds;
  message: string;
}

export interface EditorProject {
  id: number;
  last_opened_file_id: number;
  compile_target_file_id: number;
  sync_target: string; // enum?
  compiler: string; // enum?
  display_warnings: boolean;
  editor_theme: string; // enum?
  font_size: string;
  title: string;
  updated_at: string; // Date?
  scroll_sync: boolean;
  enable_spell_check: boolean;
  enable_latex_dev_mode: boolean;
  key_bindings: string;
  auto_compile: boolean;
  permission: {
    edit: boolean;
    comment: boolean;
    read: boolean;
    owner: boolean;
  };
  shares: { token: string; authority: number }[];

  collab: boolean;
}

export interface PdfPageInfo {
  originator: 'init' | 'viewer';
  page: number;
  x: number;
  y: number;
}
export interface EditorPositionInfo {
  originator: 'init' | 'viewer' | 'synctex';
  input: string;
  line: number;
  column: number;
  textBeforeSelection: string;
  textAfterSelection: string;
}

export interface PreviewFile {
  mimetype: string;
  thumbnail_url: string;
  file_url: string;
}

export interface Request {
  request: () => any;
  doneFunc: (res: any) => void;
  failFunc: (res: any) => void;
}

export interface RestoreVersion {
  version_id: string;
  size: number;
  last_modified: string;
}
export interface CurrentVersion {
  version_id: string;
  size: number;
}
export interface RestoreDialog {
  loading: boolean;
  restoring: boolean;
  versions: Array<RestoreVersion>;
  current_version: CurrentVersion;
}

export interface MoveDialog {
  open: boolean;
  loading: boolean;
  restoring: boolean;
  files: Dictionary<ProjectFile[]>;
  current: ProjectFile;
  breadcrumbs: ProjectFile[];
  fileDialogOpen: boolean;
  path: string;
}

export interface ProjectFile {
  is_folder: boolean;
  id: number;
  name: string;
  size: number;
  mimetype: string;
  content?: string | null;
  belonging_to: number; // id
  full_path: string;
  file_url: string;
  thumbnail_url?: string;
  is_new: boolean;
  is_open: boolean;
  revision: string;
}

export interface ResultError {
  error_log: string;
  url?: string;
  answer?: string;
  filename?: string;
  line?: number;
  message?: string;
}

export interface ResultWarning {
  warning_log: string;
  url?: string;
  answer?: string;
  filename?: string;
  line?: number;
  message?: string;
}

export interface Result {
  timestamp: number;
  uri: string;
  errors: Array<ResultError>;
  warnings: Array<ResultWarning>;
  log: string;
  mode: CompileMode;
  texcount: TexCount;
}

export interface TexCount {
  total_words: number;
  body_words: number;
  total_chars: number;
  body_chars: number;
}

export interface PDFFile {
  uri: string;
  pdfFileName: string;
}

export interface DocumentResult {
  exitCode?: number;
  pdfFiles: Array<PDFFile>;
}

export interface UploadFile extends Blob {
  name: string;
  size: number;
  overWritten: boolean;
  relativePath?: string;
  webkitRelativePath?: string;
}

export interface UploadStatuses {
  opened: boolean;
  uploading: boolean;
  uploaded: number;
  progress: Array<{
    files: Array<{ name: string }>;
    loaded: number;
    total: number;
  }>;
  fail: Array<{
    files: Array<{ name: string }>;
  }>;
}

export type BibtexItems = Array<string>;

// projects

export type ImportProjectKey = string;
export type ImportProjects = Array<ImportProjectKey>;

export interface Project {
  id: number;
  description: string;
  has_import_error: boolean;
  is_completed: boolean;
  title: string;
  owner: { name: string };
  size: string;
  created_at: string; // Date?
  updated_at: string; // Date?
  sync_target: string; // enum?
  shares: { token: string; authority: number }[];
}
export type Projects = Array<Project>;

export interface ProjectUser {
  state: number; // enum?
  state_label: string; // Readable state name like standard or premium
  features: { [key: string]: true };
}

export interface ProjectsError {
  open: boolean;
  handle_kind: HandleKinds;
  message: string;
}

interface Template {
  id: number;
  title: string;
  thumbnail: string;
  description: string;
}
export type Templates = Array<Template>;

export interface Runnable {
  run: () => void;
}

export interface UsageProps {
  project_num: number;
  max_project_num: number | 'Infinity';
  storage_usage: number;
  storage_size: number | 'Infinity';
}

// enums

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum DateColumn {
  UPDATED_AT,
  CREATED_AT,
}
